import {
    Box,
    Checkbox,
    CheckboxGroup,
    Flex,
    HStack,
    Heading,
    MenuItem,
    Radio,
    RadioGroup,
    Skeleton,
    Spacer,
    Stack,
    Text,
} from '@chakra-ui/react'
import { format, parseISO, subDays, isAfter, startOfDay } from 'date-fns'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import {
    hoikuQualificationChoices,
    jobOfferTypeChoices,
    kaigoQualificationChoices,
    kangoQualificationChoices,
    nutritionistQualificationChoices,
    othersQualificationChoices,
    ptotstQualificationChoices,
    updatedDateChoices,
} from 'shared'

import { FilterComponent } from '../AcutualSalariesComponents/FilterComponent'
import { JobOfferUrl } from './JobOfferUrl'
import { TextWorkingHours } from './TextWorkingHours'

import { RecommendedPoint, JobSelectButton, TextComponent } from './'

import { SITE } from '@/config'
import { getPaymentKeysAndValues } from '@/features/proposals/payment'
import { ToggleViewingModeStateContext } from '@/components/Layout'

export const JobOffer = ({
    facData,
    isLoading = false,
    isError = false,
    data,
}) => {
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    const offerArray = data?.slice().filter((item) => item.status === '募集中')
    const usualOfferArray = data
        ?.slice()
        .filter((item) => item.status === '常時募集中')
    const otherArray = data
        ?.slice()
        .filter(
            (item) => item.status !== '募集中' && item.status !== '常時募集中'
        )
    otherArray?.sort(
        (a, b) => new Date(b.GCPUpdatedAt) - new Date(a.GCPUpdatedAt)
    )
    const resultArray = []
    if (otherArray?.length > 0) {
        resultArray.unshift(...otherArray)
    }
    if (usualOfferArray?.length > 0) {
        resultArray.unshift(...usualOfferArray)
    }
    if (offerArray?.length > 0) {
        resultArray.unshift(...offerArray)
    }

    const siteType =
        SITE === 'kaigo'
            ? '介護'
            : SITE === 'hoiku'
            ? '保育'
            : SITE === 'kango'
            ? '看護'
            : ''

    const [compareDate, setCompareDate] = useState(undefined)
    const [occupationArray, setOccupationArray] = useState([siteType])

    const [filteredArray, setFilteredArray] = useState(undefined)
    useEffect(() => {
        if (resultArray) {
            const qualificationArray = setQualification()
            const offers = [...resultArray]
            setFilteredArray(filterArray(offers, qualificationArray))
        }
    }, [data])

    useEffect(() => {
        if (resultArray.length) {
            const qualificationArray = setQualification()
            const offers = [...resultArray]
            setFilteredArray(filterArray(offers, qualificationArray))
        }
    }, [occupationArray, compareDate])

    const setQualification = () => {
        let qualificationArray = []
        // チェックボックスで選択された職種に対応する資格をセットする
        if (occupationArray.includes('介護')) {
            qualificationArray = [
                ...qualificationArray,
                ...kaigoQualificationChoices,
            ]
        }
        if (occupationArray.includes('保育')) {
            qualificationArray = [
                ...qualificationArray,
                ...hoikuQualificationChoices,
            ]
        }
        if (occupationArray.includes('看護')) {
            qualificationArray = [
                ...qualificationArray,
                ...kangoQualificationChoices,
            ]
        }
        if (occupationArray.includes('PT・OT・ST')) {
            qualificationArray = [
                ...qualificationArray,
                ...ptotstQualificationChoices,
            ]
        }
        if (occupationArray.includes('栄養士')) {
            qualificationArray = [
                ...qualificationArray,
                ...nutritionistQualificationChoices,
            ]
        }
        if (occupationArray.includes('その他')) {
            qualificationArray = [
                ...qualificationArray,
                ...othersQualificationChoices,
            ]
        }
        return qualificationArray
    }

    const filterArray = (offerArray, qualificationArray) => {
        let array = []
        offerArray.map((item) => {
            // 職種の選択値がある場合のみ絞り込む
            if (occupationArray.length) {
                const separatedQualification = item?.qualification?.split(';')
                const isOccupationMatch = separatedQualification?.some(
                    (qualification) =>
                        qualificationArray.includes(qualification)
                )
                if (!isOccupationMatch) return
            }
            // 更新日が全て以外の時のみ絞り込む
            if (compareDate) {
                const isNewer = isAfter(new Date(item.updatedAt), compareDate)
                if (!isNewer) return
            }
            array = [...array, item]
        })
        return array
    }

    const occupationChange = (e) => {
        if (e.target.checked) {
            setOccupationArray([...occupationArray, e.target.value])
        } else {
            setOccupationArray(
                occupationArray.filter((v) => v !== e.target.value)
            )
        }
    }

    const changeDate = (e) => {
        const today = new Date()
        if (e.target.value === 'all') {
            setCompareDate(undefined)
            return
        }
        const date = parseInt(e.target.value)
        // 今日の日付から更新日の入力値を差し引く
        // 絞り込み実行時間によって結果が変わらないように時間は0:00:00でセットする
        setCompareDate(startOfDay(subDays(today, date)))
    }

    // 給与の表示
    const paymentView = (payment) => {
        const from = payment?.from
        const to = payment?.to
        if (to === 0) {
            return `${from.toLocaleString()}〜`
        }
        if (from > to) {
            return `${to.toLocaleString()}〜${from.toLocaleString()}`
        }
        return `${from.toLocaleString()}〜${to.toLocaleString()}`
    }

    return (
        <>
            <Skeleton isLoaded={!isLoading} minH={6}>
                {isError ? (
                    <Text>エラーが発生しました</Text>
                ) : data?.length ? (
                    <Box>
                        <HStack pt={2}>
                            <FilterComponent label="職種">
                                <CheckboxGroup value={occupationArray}>
                                    {jobOfferTypeChoices.map(
                                        ({ label, value }) => (
                                            <MenuItem key={label?.toString()}>
                                                <Checkbox
                                                    onChange={occupationChange}
                                                    value={value}
                                                >
                                                    {label}
                                                </Checkbox>
                                            </MenuItem>
                                        )
                                    )}
                                </CheckboxGroup>
                            </FilterComponent>
                            <FilterComponent label="更新日">
                                <RadioGroup defaultValue="all">
                                    {updatedDateChoices.map(
                                        ({ label, value }) => (
                                            <MenuItem key={label?.toString()}>
                                                <Radio
                                                    onChange={changeDate}
                                                    value={value}
                                                >
                                                    {label}
                                                </Radio>
                                            </MenuItem>
                                        )
                                    )}
                                </RadioGroup>
                            </FilterComponent>
                        </HStack>
                        {!filteredArray.length ? (
                            <Text>絞り込み結果0件</Text>
                        ) : (
                            filteredArray.map((offer, index) => {
                                const [, paymentValues, unit, price] =
                                    getPaymentKeysAndValues(offer)
                                return (
                                    <Stack key={offer._id} py="10px">
                                        <Flex>
                                            {offer.title && (
                                                <Heading size="1rem">
                                                    {!isJobseekerViewing
                                                        ? offer.title
                                                        : `募集案件${
                                                              index + 1
                                                          }`}
                                                </Heading>
                                            )}
                                            <Spacer />
                                            <JobSelectButton
                                                facility={facData}
                                                offer={offer}
                                            />
                                        </Flex>
                                        <TextComponent label="保有資格">
                                            {offer.qualification}
                                        </TextComponent>
                                        {paymentValues && (
                                            <TextComponent label="給与">
                                                {`${unit}${paymentView(
                                                    paymentValues
                                                )}${price}`}
                                            </TextComponent>
                                        )}

                                        <TextComponent label="勤務形態">
                                            {offer.workShift}
                                        </TextComponent>
                                        {offer?.workingHours.length && (
                                            <TextWorkingHours label="勤務時間">
                                                <HStack>
                                                    {offer?.workingHours.map(
                                                        (hours) => (
                                                            <Text
                                                                key={hours._id}
                                                            >
                                                                {`${hours.from} ~ ${hours.to}`}
                                                            </Text>
                                                        )
                                                    )}
                                                </HStack>
                                            </TextWorkingHours>
                                        )}
                                        <TextComponent label="雇用形態">
                                            {offer.employmentType}
                                        </TextComponent>
                                        <TextComponent label="募集ポジション">
                                            {offer.layer}
                                        </TextComponent>
                                        <TextComponent label="募集状況">
                                            {offer.status}
                                        </TextComponent>
                                        <TextComponent label="更新日">
                                            {format(
                                                parseISO(offer.updatedAt),
                                                'yyyy年MM月dd日'
                                            )}
                                        </TextComponent>
                                        {!isJobseekerViewing && (
                                            <JobOfferUrl label="案件SFURL">
                                                {offer.jomUrl}
                                            </JobOfferUrl>
                                        )}
                                        <RecommendedPoint>
                                            {offer.salesTalk}
                                        </RecommendedPoint>
                                    </Stack>
                                )
                            })
                        )}
                    </Box>
                ) : (
                    <Box>
                        <Text>0件</Text>
                    </Box>
                )}
            </Skeleton>
        </>
    )
}
JobOffer.propTypes = {
    facData: PropTypes.any,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    data: PropTypes.any,
}
