import { components } from './components'
import { foundations } from './foundations'
import { layerStyles } from './layer-styles'
import { textStyles } from './text-styles'

export const appTheme = {
    ...foundations,
    layerStyles,
    textStyles,
    components,
}
