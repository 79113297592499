import site from 'shared/utils/site'

const hoikuCommonServiceTypeChoices = [
    {
        label: '保育園',
        options: [
            { label: '認可保育園', value: '401' },
            { label: '小規模保育園', value: '402' },
            { label: '認可外保育園', value: '405' },
            { label: 'ベビーホテル', value: '412' },
        ],
    },
    {
        label: '幼稚園',
        options: [
            { label: '幼稚園‐公立', value: '406' },
            { label: '幼稚園‐私立', value: '407' },
        ],
    },
    {
        label: 'こども園',
        options: [
            { label: '認定こども園－保育所型', value: '409' },
            { label: '認定こども園－幼稚園型', value: '410' },
            { label: '認定こども園－幼保連携型', value: '411' },
            { label: '認定こども園－地方裁量型', value: '408' },
        ],
    },
    {
        label: '介護系施設',
        options: [
            { label: '放課後デイ', value: '222' },
            { label: '児童発達支援', value: '220' },
        ],
    },
    {
        label: '認証・認定保育園',
        options: [{ label: '認証・認定保育園', value: '403' }],
    },
    {
        label: '院内・企業内保育園',
        options: [{ label: '院内・企業内保育園', value: '404' }],
    },
    {
        label: '居宅',
        options: [
            { label: 'ベビーシッター（居宅訪問型保育）', value: '413' },
            { label: '居宅訪問型児童発達支援', value: '223' },
        ],
    },
    {
        label: '障害者施設',
        options: [
            { label: '児童発達支援', value: '220' },
            { label: '医療型児童発達支援', value: '221' },
            { label: '放課後デイ', value: '222' },
            { label: '障害児入所', value: '225' },
            { label: '障害児相談支援', value: '226' },
        ],
    },
]
const kaigoCommonServiceTypeChoices = [
    {
        label: '訪問',
        options: [
            { label: '訪問介護', value: '101' },
            { label: '夜間対応型訪問介護', value: '102' },
            { label: '訪問リハ', value: '105' },
            { label: '訪問入浴', value: '103' },
            { label: '定期巡回', value: '121' },
            { label: '訪問看護', value: '104' },
        ],
    },
    {
        label: '通所',
        options: [
            { label: 'デイ', value: '106' },
            { label: 'デイケア', value: '107' },
        ],
    },
    {
        label: '入所',
        options: [
            { label: 'ショートステイ', value: '108' },
            { label: 'GH', value: '110' },
            { label: '特養', value: '115' },
            { label: '地域密着特養', value: '116' },
            { label: '介護付き有老', value: '111' },
            { label: '住宅型有老', value: '112' },
            { label: '老健', value: '109' },
            { label: 'サ高住', value: '113' },
            { label: '小多機', value: '117' },
            { label: '看多機', value: '118' },
            { label: 'ケアハウス', value: '114' },
            { label: '介護医療院', value: '120' },
            { label: '病院', value: '119' },
        ],
    },
    {
        label: '相談支援',
        options: [
            { label: '居宅介護支援', value: '122' },
            { label: '地域包括', value: '123' },
            { label: '福祉用具', value: '124' },
        ],
    },
    {
        label: '障害訪問',
        options: [
            { label: '行動援護', value: '201' },
            { label: 'ガイドヘルパー', value: '203' },
            { label: '重度障害者等包括支援', value: '202' },
        ],
    },
    {
        label: '障害通所',
        options: [
            { label: '生活介護', value: '205' },
            { label: '自立訓練（生活訓練）', value: '211' },
            { label: '自立訓練（機能訓練）', value: '210' },
            { label: '就労定着支援', value: '219' },
            { label: '就労A', value: '212' },
            { label: '就労B', value: '213' },
            { label: '就労移行', value: '217' },
            { label: '療養介護', value: '204' },
            { label: '自立援助', value: '218' },
        ],
    },
    {
        label: '障害入所',
        options: [
            { label: '障害者施設', value: '207' },
            { label: '障害GH', value: '208' },
            { label: '宿泊型自立訓練', value: '209' },
            { label: '障害者ショートステイ', value: '206' },
        ],
    },
    {
        label: '障害相談支援',
        options: [
            { label: '計画相談支援', value: '214' },
            { label: '地域相談支援（地域移行支援）', value: '215' },
            { label: '地域相談支援（地域定着支援）', value: '216' },
            { label: '障害児相談支援', value: '226' },
        ],
    },
    {
        label: '障害児童',
        options: [
            { label: '児童発達支援', value: '220' },
            { label: '保育所等訪問支援', value: '224' },
            { label: '放課後デイ', value: '222' },
            { label: '居宅訪問型児童発達支援', value: '223' },
            { label: '医療型児童発達支援', value: '221' },
            { label: '障害児入所', value: '225' },
        ],
    },
    // { label: 'その他', options: [{ label: 'その他', value: '0' }] },
]
const kangoCommonServiceTypeChoices = [
    {
        label: '病院',
        options: [
            { label: '病院', value: '304' },
            { label: '介護医療院', value: '120' },
        ],
    },
    {
        label: 'クリニック',
        options: [
            { label: 'クリニック', value: '301' },
            { label: '有床クリニック', value: '303' },
        ],
    },
    {
        label: '訪問',
        options: [
            { label: '訪問診療', value: '302' },
            { label: '訪問看護', value: '104' },
            { label: '訪問入浴', value: '103' },
        ],
    },
    {
        label: '介護施設',
        options: [
            { label: '老健', value: '109' },
            { label: '特養', value: '115' },
            { label: '介護付き有老', value: '111' },
            { label: 'サ高住', value: '113' },
            { label: 'デイ', value: '106' },
            { label: 'その他介護施設', value: '0' },
        ],
    },
    {
        label: '障害施設',
        options: [
            { label: '重心施設', value: '310' },
            { label: 'その他障害施設', value: '201' },
        ],
    },
    {
        label: '保育施設',
        options: [
            { label: '保育園', value: '401' },
            { label: '幼稚園', value: '406' },
            { label: 'こども園', value: '408' },
        ],
    },
    {
        label: 'その他',
        options: [
            { label: '企業系', value: '305' },
            { label: '看護学校', value: '306' },
        ],
    },
]

export const commonServiceTypeChoices = site.isHoiku()
    ? hoikuCommonServiceTypeChoices
    : site.isKango()
    ? kangoCommonServiceTypeChoices
    : kaigoCommonServiceTypeChoices
