// see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/input.ts
// see: https://zenn.dev/tokutsu/articles/edd55fd6c0e7a9

export const inputTheme = {
    variants: {
        outline: {
            field: {
                bg: 'white',
            },
        },
    },
    sizes: {
        md: {
            field: {
                fontSize: 'sm',
                fontFamily: 'fonts',
            },
        },
    },
    defaultProps: {
        focusBorderColor: 'primary.500',
        fontFamily: 'fonts',
    },
}
