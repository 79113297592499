import { Box, Grid, GridItem, HStack } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { LogoMenu } from '../LogoMenu'
import { Footer } from '../MainLayout/Footer'

export const AdminLayout = ({ children }) => {
    return (
        <Box
            minHeight="100vh"
            sx={{
                '--header-height': '100px',
            }}
        >
            <Grid
                h="full"
                gridTemplateRows="auto 1fr auto"
                className="admin-layout"
                overflow="auto"
            >
                <GridItem
                    as="header"
                    position="sticky"
                    top={0}
                    zIndex="banner"
                    bg="white"
                    minW={0}
                >
                    <HStack p="16px 10px 16px 10px">
                        <LogoMenu home="/admin/" />
                    </HStack>
                </GridItem>
                <GridItem as="main">{children}</GridItem>
                <GridItem as="footer">
                    <Footer />
                </GridItem>
            </Grid>
        </Box>
    )
}
AdminLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
