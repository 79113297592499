export const updatedDateChoices = [
    {
        label: '全て',
        value: 'all',
    },
    {
        label: '1週間以内',
        value: '7',
    },
    {
        label: '1ヶ月以内',
        value: '30',
    },
    {
        label: '3ヶ月以内',
        value: '90',
    },
    {
        label: '半年以内',
        value: '180',
    },
    {
        label: '1年以内',
        value: '365',
    },
]

export const jobOfferTypeChoices = [
    {
        label: '介護',
        value: '介護',
    },
    {
        label: '保育',
        value: '保育',
    },
    {
        label: '看護',
        value: '看護',
    },
    {
        label: 'PT・OT・ST',
        value: 'PT・OT・ST',
    },
    {
        label: '栄養士',
        value: '栄養士',
    },
    {
        label: 'その他',
        value: 'その他',
    },
]

export const kaigoQualificationChoices = [
    '介護福祉士',
    'ヘルパー１級', //実務者研修 DBの値がヘルパー1級のためこの表記
    'ヘルパー２級', //初任者研修  DBの値がヘルパー2級のためこの表記
    '介護職・ヘルパー',
    '登録ヘルパー',
    '登録ヘルパー（訪問介護）',
    '資格なし',
    '介護支援専門員',
    '主任介護支援専門員',
    '支援員',
    '社会福祉士',
    '精神保健福祉士（PSW）',
    '医療ソーシャルワーカー（MSW）',
    '社会福祉主事',
    '生活相談員',
    '支援相談員',
    '看護助手',
    'サービス提供責任者',
    'サービス管理責任者',
    '児童発達支援管理責任者',
    '児童発達支援管理者',
    '施設長（施設長候補）',
    '施設長候補',
    '柔道整復師',
    '福祉用具専門相談員',
    '事務',
    '医療事務',
    '精神保健福祉士',
    '介護タクシー(ドライバー)',
]

export const hoikuQualificationChoices = [
    '児童発達支援管理責任者',
    '園長',
    '保育士',
    '保育教諭',
    '幼稚園教諭',
    '保育補助',
    '児童指導員',
    '放課後児童支援員/学童指導員',
]

export const kangoQualificationChoices = [
    '正看護師',
    '助産師',
    '保健師',
    '准看護師',
    '看護助手',
    '治験コーディネーター',
    '医師',
    '薬剤師',
    '歯科衛生士',
]

export const ptotstQualificationChoices = [
    '理学療法士',
    '作業療法士',
    '言語聴覚士',
    'PT',
    'OT',
    'ST',
    'あん摩マッサージ指圧師',
    '臨床工学技士',
    '臨床検査技師',
    '診療放射線技師',
    'ME',
    'MT',
    'RT',
]

export const nutritionistQualificationChoices = [
    '栄養士',
    '管理栄養士',
    '調理師',
]

export const othersQualificationChoices = ['その他']
