import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

// TODO: 内容を決める
const Head = ({ title = '', errorTitle = '', description = '' }) => {
    return (
        <Helmet
            title={
                title
                    ? `${title} | TRYT Recommendation System`
                    : errorTitle
                    ? `${errorTitle} | Forward`
                    : undefined
            }
            defaultTitle="TRYT Recommendation System"
        >
            <meta name="description" content={description} />
        </Helmet>
    )
}

Head.propTypes = {
    title: PropTypes.string,
    errorTitle: PropTypes.string,
    description: PropTypes.string,
}

export { Head }
