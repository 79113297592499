export const layerStyles = {
    dataTable: {
        '--table-cell-padding': 'var(--chakra-space-2)',
        '--narrow-column': '150px',
        '--narrow-column-2': '180px',
        '--narrow-column-kpi': '500px',
        '--button-column': '90px',

        table: {
            w: 'full',
            tableLayout: 'fixed',
            borderCollapse: 'separate',
            borderSpacing: '4px',

            thead: {
                bg: '#8DD9B4',
            },

            tbody: {
                bg: 'white',
            },

            th: {
                color: 'white',
                fontSize: 'sm',
                p: '4px 24px',
                '&.narrow': {
                    w: 'var(--narrow-column)',
                },
                '&.narrow-2': {
                    w: 'var(--narrow-column-2)',
                },
                '&.narrow-kpi': {
                    w: 'var(--narrow-column-kpi)',
                },
                '&.button': {
                    w: 'var(--button-column)',
                },
            },

            td: {
                color: 'black',
                fontSize: 'md',
                p: '4px 24px',
                borderBottom: 'none',
            },

            'th, td': {
                '&.center': {
                    textAlign: 'center',
                },
                '&.right': {
                    textAlign: 'right',
                },
            },
        },
    },
}
