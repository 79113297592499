import {
    Button,
    Text,
    ButtonGroup,
    VStack,
    HStack,
    Spacer,
    Box,
    FormErrorMessage,
    FormControl,
    Badge,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useContext, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { LogoMenu } from '../../LogoMenu'
import { HeaderSearchButton } from './HeaderSearchButton'
import { HeaderSfId } from './HeaderSfId'

import { DashboardIcon, DocumentIcon } from '@/assets/icon'
import { FreewordInput } from '@/components/Elements'
import { setFreeword } from '@/stores'
import { backendApi } from '@/stores/backendApi'
import { ToggleViewingModeStateContext } from '../MainLayout'

export const HeaderButton = ({ children, onClick }) => (
    <Button
        variant="solid"
        h="56px"
        w="56px"
        textAlign="center"
        fontSize="10px"
        borderRadius="lg"
        onClick={onClick}
    >
        {children}
    </Button>
)
HeaderButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
}

export const MunuListButton = ({ children, onClick }) => (
    <Button
        variant="solid"
        w="200px"
        borderRadius="lg"
        onClick={() => onClick()}
    >
        {children}
    </Button>
)
MunuListButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
}

export const Header = () => {
    const navigate = useNavigate()
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    return (
        <HStack p="16px 10px 16px 10px">
            <LogoMenu />
            <Spacer />
            <ButtonGroup
                sx={{
                    '@media print': {
                        display: 'none',
                    },
                }}
            >
                <HeaderButton onClick={() => navigate('/dashboard')}>
                    <VStack>
                        <DashboardIcon width="20px" height="20px" />
                        <p
                            style={{
                                width: '45px',
                                marginTop: '1px',
                                whiteSpace: 'initial',
                            }}
                        >
                            ダッシュボード
                        </p>
                    </VStack>
                </HeaderButton>
                <HeaderSearchButton>
                    <HeaderButton>
                        <VStack>
                            <DocumentIcon />
                            <Text>提案書作成</Text>
                        </VStack>
                    </HeaderButton>
                </HeaderSearchButton>
            </ButtonGroup>
            <Spacer />
            <Box
                w="600px"
                minW="250px"
                sx={{
                    '@media print': {
                        display: 'none',
                    },
                }}
                pt="16px"
            >
                <FreewordForm />
            </Box>
            <Spacer />
            <HStack
                whiteSpace="nowrap"
                minW="300px"
                sx={{
                    '@media print': {
                        display: 'none',
                    },
                }}
            >
                {!isJobseekerViewing ? (
                    <HeaderSfId />
                ) : (
                    <Badge
                        fontSize="16px"
                        variant="outline"
                        boxShadow="0 0 0 1px #3CB37A"
                        color="#3CB37A"
                    >
                        候補者モード
                    </Badge>
                )}
            </HStack>
        </HStack>
    )
}

const FreewordForm = () => {
    const value = useSelector((state) => state.search.freeword)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [writeSearchLog] = backendApi.endpoints.writeSearchLog.useMutation()
    const inputRef = useRef(null)
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                const trimmedValue = value.trim()
                dispatch(setFreeword(trimmedValue))
                inputRef.current.value = trimmedValue

                writeSearchLog({
                    data: {
                        q: trimmedValue,
                    },
                    msg: 'フリーワード検索が実行されました。',
                    function: 'freeword-search',
                })
                const to =
                    location.pathname.replace(/(.+)\/$/, '$1') ===
                    '/facilities/map'
                        ? '/facilities/map'
                        : '/facilities'
                navigate(to, {
                    state: {
                        mode: 'freewordOnly',
                        isNavigate: true,
                    },
                })
            }}
        >
            <FormControl
                isInvalid={
                    location.state?.error?.payload?.data?.name ===
                        'MongoServerError' &&
                    location.state?.error?.payload?.data?.searchWord === value
                }
            >
                <FreewordInput
                    placeholder="フリーワード検索（法人/施設名など）"
                    value={value ?? ''}
                    inputRef={inputRef ?? ''}
                    onClear={(e) => {
                        inputRef.current.value = ''
                        dispatch(setFreeword(e.target.value))
                    }}
                    onChange={(e) => {
                        dispatch(setFreeword(e.target.value))
                    }}
                    onBlur={(e) => {
                        const trimmedValue = e.target.value.trim()
                        dispatch(setFreeword(trimmedValue))
                        inputRef.current.value = trimmedValue
                    }}
                />
                <FormErrorMessage>
                    検索時にエラーが発生しました。
                </FormErrorMessage>
            </FormControl>
        </form>
    )
}
// FreewordForm.whyDidYouRender = true
