import { Box, Text, Button, Skeleton, HStack, Stack } from '@chakra-ui/react'
import { format, parseISO } from 'date-fns'
import PropTypes from 'prop-types'

import { TextComponent } from './'

import { ExternalLink } from '@/components/Elements'
import { getPaymentKeysAndValues } from '@/features/proposals/payment'

export const HelloWorkItem = ({ isLoading = false, isError = false, data }) => {
    // 給与の表示
    const paymentView = (payment) => {
        const from = payment?.from
        const to = payment?.to
        if (to === 0) {
            return `${from.toLocaleString()}〜`
        }
        if (from > to) {
            return `${to.toLocaleString()}〜${from.toLocaleString()}`
        }
        return `${from.toLocaleString()}〜${to.toLocaleString()}`
    }

    return (
        <>
            <Skeleton isLoaded={!isLoading} minH={6}>
                {isError ? (
                    <Text>エラーが発生しました</Text>
                ) : data?.length ? (
                    <Stack>
                        {data?.map((offer) => {
                            const [, paymentValues, unit, price] =
                                getPaymentKeysAndValues(offer)
                            return (
                                <Stack key={offer._id} py="10px">
                                    <TextComponent label="職種">
                                        {offer.hwJson?.sksu}
                                    </TextComponent>
                                    <TextComponent label="保有資格">
                                        {offer.qualification}
                                    </TextComponent>
                                    {paymentValues && (
                                        <TextComponent label="給与">
                                            {`${unit}${paymentView(
                                                paymentValues
                                            )}${price}`}
                                        </TextComponent>
                                    )}
                                    <TextComponent label="勤務形態">
                                        {offer.workShift}
                                    </TextComponent>
                                    <TextComponent label="雇用形態">
                                        {offer.employmentType}
                                    </TextComponent>

                                    <TextComponent label="受付年月日">
                                        {format(
                                            parseISO(offer.updatedAt),
                                            'yyyy年MM月dd日'
                                        )}
                                    </TextComponent>
                                    <Box paddingTop="8px" paddingLeft="250px">
                                        <Button
                                            as={ExternalLink}
                                            href={`/offers/hellowork/${offer.kjno}`}
                                        >
                                            詳細を表示
                                        </Button>
                                    </Box>
                                </Stack>
                            )
                        })}
                    </Stack>
                ) : (
                    <Box>
                        <Text>0件</Text>
                    </Box>
                )}
            </Skeleton>
        </>
    )
}

HelloWorkItem.propTypes = {
    facData: PropTypes.any,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    data: PropTypes.any,
}
