import { useCallback, useState } from 'react'

import { USE_SF_API } from '@/config'
// eslint-disable-next-line no-restricted-imports
import {
    useGetJobseekersQuery,
    useGetSfAccountDetailQuery,
    useLazyGetJobseekersQuery,
    useLazyGetSfAccountDetailQuery,
} from '@/features/jobseekers/api'

const merge = (sfId, backendResult, sfResult) => {
    const {
        data: backendJobseeker,
        isLoading: backendIsLoading,
        isFetching: backendIsFetching,
        isError: backendIsError,
        isSuccess: backendIsSuccess,
    } = backendResult
    const {
        data: sfJobseeker,
        isLoading: sfIsLoading,
        isFetching: sfIsFetching,
        isError: sfIsError,
        isSuccess: sfIsSuccess,
    } = sfResult

    const mergedJobseeker =
        !backendJobseeker && !sfJobseeker
            ? undefined
            : {
                  ...backendJobseeker,
                  ...sfJobseeker,
                  sfId
              }

    // TODO: 他データ追加
    return {
        data: mergedJobseeker,
        isLoading: backendIsLoading && sfIsLoading,
        isFetching: backendIsFetching && sfIsFetching,
        isError: backendIsError && (sfIsError || sfJobseeker === undefined),
        isSuccess: backendIsSuccess || sfIsSuccess,
    }
}

export const useGetMergedJobseekerQuery = !USE_SF_API
    ? useGetJobseekersQuery
    : (sfId) => {
        const backendResult = useGetJobseekersQuery(sfId)
        const sfResult = useGetSfAccountDetailQuery(sfId)

        if(backendResult?.error?.status === 404) {
            backendResult.data = null
        }

        return merge(sfId, backendResult, sfResult)
    }

export const useLazyGetMergedJobseekerQuery = !USE_SF_API
    ? useLazyGetJobseekersQuery
    : () => {
        const [fetchBackendJobseeker, backendResult] =
            useLazyGetJobseekersQuery()
        const [fetchSfJobseeker, sfResult] = useLazyGetSfAccountDetailQuery()
        const [lastSfId, setLastSfId] = useState(undefined);

        const trigger = useCallback(
            (sfId) => {
                const promise = Promise.allSettled([
                    fetchBackendJobseeker(sfId).unwrap(),
                    fetchSfJobseeker(sfId).unwrap(),
                ]).then((results) => {
                    if(results[0].status === 'rejected' && (results[1].status === 'rejected' || results[1].value === undefined)) {
                        setLastSfId(undefined);
                        return
                    }

                    // @ts-ignore
                    const [{ value: bkJs }, { value: sfJs }] = results
                    const jobseeker = {
                        ...bkJs,
                        ...sfJs,
                        sfId,
                        source: { backend: !!bkJs, sf: !!sfJs },
                    } // 後勝ち
                    setLastSfId(sfId);
                    return jobseeker
                })

                return { unwrap: () => promise }
            },
            [fetchBackendJobseeker, fetchSfJobseeker]
        )

        const result = merge(lastSfId, backendResult, sfResult)

        return [trigger, result]
    }
