import site from 'shared/utils/site'

// 勤務形態選択肢
const kaigoWorkShiftChoices = [
    { label: 'フル常勤', value: 'フル常勤' },
    { label: '日勤常勤', value: '日勤常勤' },
    { label: '夜勤常勤', value: '夜勤常勤' },
    { label: '日勤パート', value: '日勤パート' },
    { label: '夜勤バイト', value: '夜勤パート' },
    { label: 'その他', value: 'その他' },
]

const kangoWorkShiftChoices = [
    { label: 'フル常勤', value: 'フル常勤' },
    { label: '日勤常勤', value: '日勤常勤' },
    { label: '夜勤常勤', value: '夜勤常勤' },
    { label: '日勤パート', value: '日勤パート' },
    { label: 'フルタイムパート', value: 'フルタイムパート' },
    { label: '夜勤バイト', value: '夜勤パート' },
    { label: 'その他', value: 'その他' },
]

export const workShiftChoices = site.isKango()
    ? kangoWorkShiftChoices
    : kaigoWorkShiftChoices
