import { Button } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useState } from 'react'

import { ChatIcon } from '@/assets/icon'
import { NODE_BB_BASE_URL } from '@/config'

/**
 * @param {{ backURL?: string; close?: any }} param0
 * @returns {string}
 */
const newContactUrl = ({}) => {
    const url = `${NODE_BB_BASE_URL}/recent`
    return url
}

const windowName = 'contactWindow'

export const useNewContact = () => {
    const [newContactWindow, setNewContactWindow] = useState(null)
    const backURL = location.pathname === '/contacts' ? location.href : null
    const url = useMemo(
        () => newContactUrl({ backURL: backURL, close: true }),
        []
    )

    const openNewContact = useCallback(() => {
        const w = (screen.availWidth - 1000) / 2
        const h = (screen.availHeight - 700) / 2

        if (newContactWindow && !newContactWindow.closed) {
            newContactWindow.focus()
        } else {
            const newWindow = window.open(
                url,
                windowName,
                `width=1000,height=700,left=${w},top=${h}`
            )
            setNewContactWindow(newWindow)
        }
    }, [url, newContactWindow, setNewContactWindow])

    return openNewContact
}

export const useContactList = () => {
    const [contactListWindow, setContactListWindow] = useState(null)
    const url = `${NODE_BB_BASE_URL}/recent`

    const openContactList = useCallback(() => {
        const w = (screen.availWidth - 1000) / 2
        const h = (screen.availHeight - 700) / 2

        if (contactListWindow && !contactListWindow.closed) {
            contactListWindow.focus()
        } else {
            const newWindow = window.open(
                url,
                windowName,
                `width=1000,height=700,left=${w},top=${h}`
            )
            setContactListWindow(newWindow)
        }
    }, [contactListWindow, setContactListWindow])

    return openContactList
}

export const NewContactButton = ({ backURL, close, isHeader }) => {
    const openNewContact = useNewContact()
    const url = newContactUrl({ backURL, close })

    return (
        <Button
            cursor="pointer"
            as="a"
            variant="link"
            onClick={openNewContact}
            {...(isHeader && {
                colorScheme: 'black',
                leftIcon: <ChatIcon />,
            })}
        >
            新規お問い合わせ
        </Button>
    )
}

NewContactButton.propTypes = {
    backURL: PropTypes.string,
    close: PropTypes.any,
    isHeader: PropTypes.bool,
}
