import { Flex } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { RecruitmentItem } from './RecruitmentItem'

import { ExternalLink } from '@/components/Elements'

export const JobOfferUrl = ({ label, children }) => {
    return (
        children && (
            <Flex>
                {label && <RecruitmentItem>{label}</RecruitmentItem>}
                <ExternalLink href={children} wordBreak="break-all" ml={5}>
                    {children}
                </ExternalLink>
            </Flex>
        )
    )
}

JobOfferUrl.propTypes = {
    label: PropTypes.string,
    //url: PropTypes.string,
    children: PropTypes.string,
}
