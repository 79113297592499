const sites = {
    Kaigo: 'kaigo',
    Hoiku: 'hoiku',
    Kango: 'kango',
}

const methods = {
    get value() {
        return process.env.SITE
    },
    isDefined() {
        return Object.values(sites).some((site) => site === this.value)
    },
    isKaigo() {
        return sites.Kaigo === this.value
    },
    isHoiku() {
        return sites.Hoiku === this.value
    },
    isKango() {
        return sites.Kango === this.value
    },
}

module.exports = new Proxy(methods, {
    get(target, prop) {
        if (prop in target) {
            return target[prop]
        } else {
            return sites[prop]
        }
    },
})
