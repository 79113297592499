import site from 'shared/utils/site'

// 保有資格選択肢

const hoikuOfferQualificationChoices = [
    //保育用（見た目だけ）
    { label: '保育士', value: '保育士' },
    { label: '保育教諭', value: '保育教諭' },
    { label: '児童発達支援管理責任者', value: '児童発達支援管理責任者' },
    { label: '園長', value: '園長' },
    { label: '幼稚園教諭', value: '幼稚園教諭' },
    { label: '保育補助', value: '保育補助' },
    { label: '児童指導員', value: '児童指導員' },
    {
        label: '放課後児童支援員/学童指導員',
        value: '放課後児童支援員/学童指導員',
    },
    { label: 'その他', value: 'その他' },
]
const kaigoOfferQualificationChoices = [
    // { label: '介護職・ヘルパー', value: '介護職・ヘルパー' },
    { label: '介護福祉士', value: '介護福祉士' },
    { label: '実務者研修', value: 'ヘルパー１級' }, //実務者研修 DBの値がヘルパー1級のためこの表記
    { label: '初任者研修', value: 'ヘルパー２級' }, //初任者研修  DBの値がヘルパー2級のためこの表記
    { label: '資格なし', value: '資格なし' },
    { label: '介護支援専門員', value: '介護支援専門員' },
    { label: '主任介護支援専門員', value: '主任介護支援専門員' },
    { label: '支援員', value: '支援員' },
    { label: '社会福祉士', value: '社会福祉士' },
    { label: '精神保健福祉士（PSW）', value: '精神保健福祉士（PSW）' },
    {
        label: '医療ソーシャルワーカー（MSW）',
        value: '医療ソーシャルワーカー（MSW）',
    },
    { label: '社会福祉主事', value: '社会福祉主事' },
    { label: '生活相談員', value: '生活相談員' },
    { label: '支援相談員', value: '支援相談員' },
    { label: '看護助手', value: '看護助手' },
    { label: 'サービス提供責任者', value: 'サービス提供責任者' },
    { label: 'サービス管理責任者', value: 'サービス管理責任者' },
    { label: '児童発達支援管理責任者', value: '児童発達支援管理責任者' },
    { label: '児童発達支援管理者', value: '児童発達支援管理者' },
    { label: '施設長（施設長候補）', value: '施設長（施設長候補）' },
    { label: '施設長候補', value: '施設長候補' },
    { label: '柔道整復師', value: '柔道整復師' },
    { label: '福祉用具専門相談員', value: '福祉用具専門相談員' },
    { label: '事務', value: '事務' },
    { label: '正看護師', value: '正看護師' },
    { label: '助産師', value: '助産師' },
    { label: '保健師', value: '保健師' },
    { label: '准看護師', value: '准看護師' },
    { label: '医療事務', value: '医療事務' },
    { label: '保育士', value: '保育士' },
    { label: '児童指導員', value: '児童指導員' },
    {
        label: '放課後児童支援員/学童指導員',
        value: '放課後児童支援員/学童指導員',
    },
    { label: 'その他', value: 'その他' },
    // { label: '登録ヘルパー（訪問介護）', value: '登録ヘルパー（訪問介護）' },
    // { label: '登録ヘルパー', value: '登録ヘルパー' },
    // { label: '精神保健福祉士', value: '精神保健福祉士' },
]

const kangoOfferQualificationChoices = [
    { label: '正看護師', value: '正看護師' },
    { label: '准看護師', value: '准看護師' },
    { label: '助産師', value: '助産師' },
    { label: '保健師', value: '保健師' },
    { label: '看護助手', value: '看護助手' },
    { label: '臨床検査技師', value: '臨床検査技師' },
    { label: '臨床工学技士', value: '臨床工学技士' },
    { label: '診療放射線技師', value: '診療放射線技師' },
    {
        label: '医療ソーシャルワーカー（MSW）',
        value: '医療ソーシャルワーカー（MSW）',
    },
    { label: '薬剤師', value: '薬剤師' },
    { label: '医師', value: '医師' },
    { label: '公認心理士/臨床心理士', value: '公認心理士/臨床心理士' },
    { label: '看護教員', value: '看護教員' },
    { label: '事務', value: '事務' },
    { label: 'その他', value: 'その他' },
]

const kaigoRecordQualificationChoices = [
    // { label: '介護職・ヘルパー', value: '介護職・ヘルパー' },
    { label: '介護福祉士', value: '介護福祉士' },
    { label: '実務者研修', value: 'ヘルパー1級' }, //実務者研修 DBの値がヘルパー1級のためこの表記
    { label: '初任者研修', value: 'ヘルパー2級' }, //初任者研修  DBの値がヘルパー2級のためこの表記
    { label: '資格なし', value: '資格なし' },
    { label: '介護支援専門員', value: '介護支援専門員' },
    { label: '主任介護支援専門員', value: '主任介護支援専門員' },
    { label: '支援員', value: '支援員' },
    { label: '社会福祉士', value: '社会福祉士' },
    { label: '精神保健福祉士（PSW）', value: '精神保健福祉士（PSW）' },
    {
        label: '医療ソーシャルワーカー（MSW）',
        value: '医療ソーシャルワーカー（MSW）',
    },
    { label: '社会福祉主事', value: '社会福祉主事' },
    { label: '生活相談員', value: '生活相談員' },
    { label: '支援相談員', value: '支援相談員' },
    { label: '看護助手', value: '看護助手' },
    { label: 'サービス提供責任者', value: 'サービス提供責任者' },
    { label: 'サービス管理責任者', value: 'サービス管理責任者' },
    { label: '児童発達支援管理責任者', value: '児童発達支援管理責任者' },
    { label: '児童発達支援管理者', value: '児童発達支援管理者' },
    { label: '施設長（施設長候補）', value: '施設長（施設長候補）' },
    { label: '施設長候補', value: '施設長候補' },
    { label: '柔道整復師', value: '柔道整復師' },
    { label: '福祉用具専門相談員', value: '福祉用具専門相談員' },
    { label: '事務', value: '事務' },
    { label: '正看護師', value: '正看護師' },
    { label: '助産師', value: '助産師' },
    { label: '保健師', value: '保健師' },
    { label: '准看護師', value: '准看護師' },
    { label: '医療事務', value: '医療事務' },
    { label: '保育士', value: '保育士' },
    { label: '児童指導員', value: '児童指導員' },
    {
        label: '放課後児童支援員/学童指導員',
        value: '放課後児童支援員/学童指導員',
    },
    { label: 'その他', value: 'その他' },
    // { label: '登録ヘルパー（訪問介護）', value: '登録ヘルパー（訪問介護）' },
    // { label: '登録ヘルパー', value: '登録ヘルパー' },
    // { label: '精神保健福祉士', value: '精神保健福祉士' },
]
const hoikuRecordQualificationChoices = [
    //保育用
    { label: '保育士', value: '保育士' },
    { label: '保育教諭', value: '保育教諭' },
    { label: '児童発達支援管理責任者', value: '児童発達支援管理責任者' },
    { label: '園長', value: '園長' },
    { label: '幼稚園教諭', value: '幼稚園教諭' },
    { label: '保育補助', value: '保育補助' },
    { label: '児童指導員', value: '児童指導員' },
    {
        label: '放課後児童支援員/学童指導員',
        value: '放課後児童支援員/学童指導員',
    },
    { label: 'その他', value: 'その他' },
]

const kangoRecordQualificationChoices = [
    { label: '正看護師', value: '正看護師' },
    { label: '准看護師', value: '准看護師' },
    { label: '助産師', value: '助産師' },
    { label: '保健師', value: '保健師' },
    { label: '看護助手', value: '看護助手' },
    { label: '臨床検査技師', value: '臨床検査技師' },
    { label: '臨床工学技士', value: '臨床工学技士' },
    { label: '診療放射線技師', value: '診療放射線技師' },
    {
        label: '医療ソーシャルワーカー（MSW）',
        value: '医療ソーシャルワーカー（MSW）',
    },
    { label: '薬剤師', value: '薬剤師' },
    { label: '医師', value: '医師' },
    { label: '事務', value: '事務' },
    { label: 'その他', value: 'その他' },
]

export const offerQualificationChoices = site.isHoiku()
    ? hoikuOfferQualificationChoices
    : site.isKango()
    ? kangoOfferQualificationChoices
    : kaigoOfferQualificationChoices
export const recordQualificationChoices = site.isHoiku()
    ? hoikuRecordQualificationChoices
    : site.isKango()
    ? kangoRecordQualificationChoices
    : kaigoRecordQualificationChoices
