//雇用形態選択肢
export const employmentChoices = [
    { label: '正社員', value: '正社員' },
    { label: 'パート', value: 'パート' },
    { label: '派遣', value: '派遣' },
    { label: '応援', value: '応援' },
    { label: '契約社員', value: '契約社員' },
    { label: 'その他', value: 'その他' },
    { label: '委託', value: '委託' },
    { label: '紹介予定派遣', value: '紹介予定派遣' },
]
